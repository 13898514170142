import { createTheme } from "@mui/material";
import WTTTF from '@assets/fonts/wt011.ttf';


const theme = createTheme({
  typography:{
    fontFamily : "WTTTF,Arial",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face":{
          fontFamily:"WTTTF",
          src:`url(${WTTTF}) format("truetype")`
        },
        body: {
          fontSize: "1rem",
        }
      },
    },
  },
});


export default theme;