import { ISystemUser } from '@interface/IUser'
import FetchUserActions from '@store/actions/fetchUserActions'


/** 初始狀態 */
export const INITIAL_FETCH_USER_STATE: ISystemUser = {
  userId:"" ,
  name:"",
  type:"",
  auth:{
    all:false,
    management_installer_view : false ,
    management_installer_edit : false,
    management_installer_delete : false,
    management_trader_view : false,
    management_trader_edit : false,
    management_trader_delete : false,
    management_customer_view : false,
    management_customer_delete : false,
    management_community_view : false,
    management_community_edit : false,
    management_community_delete : false,
    management_place_view : false,
    management_place_edit : false,
    management_place_delete : false,
    electricity_consumption_analysis_view : false,
    electricity_consumption_analysis_edit : false,
    electricity_consumption_analysis_delete : false,
    electricity_price_management_view : false,
    report_operating_detail : false,
    report_detail : false,
    setting_installer : false,
    setting_trader : false,
    setting_place : false,
    setting_customer : false ,
    setting_community:false
  }

}

/** 默認 reducer */
export default function fetchReducer(state: ISystemUser = INITIAL_FETCH_USER_STATE, action: FetchUserActions ) {
  switch (action.type) {
    case 'SAVE_USER': {
      return {
        ...state,
        user: action.payload.user
      }
    }

    default:
      return state
  }
}
