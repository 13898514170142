enum ResponseStatus {
    SUCCESS = 200,
    UNAUTHORIZED = 401 ,
    TIMEOUT = 408,
    UNKNOW = 999,
    ERROR_USER_LOGIN = 3005,
    ERROR_USER_OLD_PASSWORD=3004 ,
    ERROR_PERMISSIO = 1005 ,
    ERROR_LIMIT_FILE_SIZE = 1006,
    ERROR_FILE_TYPE = 1007 ,
    ERROR_CMS_INFO = 2998,
    ERROR_REGISTER_CMS = 2999,
}

export default ResponseStatus;