import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from '@store/ContextStore';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material';
import theme from "@assets/styles/theme";
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '@provider/AuthProvider';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={responsiveFontSizes(theme)}>
    <SnackbarProvider 
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
    <AppProvider>
      
        <CssBaseline />
        <Router>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Router>
    </AppProvider>
    </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
