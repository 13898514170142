import { createContext ,Dispatch, useReducer} from "react";
import FetchActions from "@store/actions/fetchActions";
import fetchReducer, { FetchState, INITIAL_FETCH_STATE } from "@store/reducer/fetchReducer";
import { ISystemUser } from "@interface/IUser";
import FetchUserActions from "@store/actions/fetchUserActions";
import fetchUserReducer, { INITIAL_FETCH_USER_STATE } from "@store/reducer/fetchUserReducer";
import FetchBreadcrumbNameActions from "@store/actions/fetchBreadcrumbNameActions";
import fetchBreadcrumbNameReducer, { FetchBreadcrumbNameState, INITIAL_FETCH_BREADCRUMBNAME_STATE } from "@store/reducer/fetchBreadcrumbNameReducer";

type InitialStateType = {
  fetch: FetchState
  user?:ISystemUser
  breadcrumbName:FetchBreadcrumbNameState
};
  
const initialState = {
  fetch: INITIAL_FETCH_STATE,
  user:INITIAL_FETCH_USER_STATE,
  breadcrumbName:INITIAL_FETCH_BREADCRUMBNAME_STATE
};

const AppContext = createContext<{
  state: InitialStateType ;
  dispatch: Dispatch<FetchActions|FetchUserActions|FetchBreadcrumbNameActions> ;
}>({
  state: initialState ,
  dispatch: () => null
});

const mainReducer = (
  {fetch , user ,breadcrumbName}: InitialStateType,
  action: FetchActions | FetchUserActions | FetchBreadcrumbNameActions
) => ({
  fetch: fetchReducer(fetch, action as FetchActions),
  user: fetchUserReducer(user, action as FetchUserActions),
  breadcrumbName: fetchBreadcrumbNameReducer(breadcrumbName, action as FetchBreadcrumbNameActions),
});

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
  
  export { AppProvider, AppContext };