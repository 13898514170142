
// baseUrl
export const baseUrl = '/cms/service';

export const GET_CITY_DISTRICT_API = `${baseUrl}/system/citydistrict/`;
export const GET_POST_SYSTEM_USER_API = `${baseUrl}/system/user/`;

export const PUT_DELETE_SYSTEM_USER_API = `${baseUrl}/system/user/%s`;
export const GET_SYSTEM_USER_DETAIL_API = `${baseUrl}/system/user/%s/detail`;

export const VERIFY_SYSTEM_USER_MAIL_API = `${baseUrl}/system/user/verifymail`;
export const REFRESH_TOKEB_API = `${baseUrl}/auth/refreshtoken/`;
export const POST_SYSTEM_LOGIN_API = `${baseUrl}/system/login/`;
export const SYSTEM_LOGOUT_API = `${baseUrl}/system/logout/`;


export const GET_SYSTEM_USER_BY_TYPE_API = `${baseUrl}/system/%s/user`;
export const PUT_SYSTEM_USER_PASSWORD_API = `${baseUrl}/system/user/password`;

export const GET_POST_CUSTOMER_API = `${baseUrl}/system/customer`;
export const DELETE_CUSTOMER_API = `${baseUrl}/system/customer/%s`;
export const GET_CUSTOMER_DETAIL_API = `${baseUrl}/system/customer/%s/detail`;
export const VERIFY_CUSTOMER_MAIL_API = `${baseUrl}/system/customer/verifymail`;

export const GET_CHARGEBOX_API = `${baseUrl}/system/chargebox`;
export const GET_PUT_CHARGEBOX_DETAIL_API = `${baseUrl}/system/chargebox/%s/detail`;
export const GET_CHARGEBOX_PRODUCT_TYPE_API = `${baseUrl}/system/chargebox/product/type`;
//設定

export const GET_SYSTEM_PERMISSION_TYPE_API = `${baseUrl}/system/permission/`;

export const GET_PUT_SYSTEM_PERMISSION_API = `${baseUrl}/system/permission/%s`;


export const GET_POST_FIELD_API = `${baseUrl}/system/setting/field`;
export const PUT_SYSTEM_FIELD_DETAIL_API = `${baseUrl}/system/setting/field/%s/detail`;
export const GET_REMAING_SYSTEM_FIELD_API = `${baseUrl}/system/field`;
export const PUT_DELETE_FIELD_API = `${baseUrl}/system/setting/field/%s`;

export const ALL_FIELD_CMS_API = `${baseUrl}/system/setting/field/%s/cms`;

export const GET_POST_CHARGEBOX_TYPE_API = `${baseUrl}/system/setting/chargebox/type`;
export const PUT_DELETE_CHARGEBOX_TYPE_API = `${baseUrl}/system/setting/chargebox/type/%s`;



const APIs = {
    GET_CITY_DISTRICT_API : GET_CITY_DISTRICT_API ,
    GET_POST_SYSTEM_USER_API : GET_POST_SYSTEM_USER_API ,
    POST_SYSTEM_LOGIN_API : POST_SYSTEM_LOGIN_API,
    SYSTEM_LOGOUT_API:SYSTEM_LOGOUT_API,
    REFRESH_TOKEB_API:REFRESH_TOKEB_API,
    GET_SYSTEM_PERMISSION_TYPE_API:GET_SYSTEM_PERMISSION_TYPE_API,
    GET_PUT_SYSTEM_PERMISSION_API:GET_PUT_SYSTEM_PERMISSION_API,
    GET_POST_FIELD_API:GET_POST_FIELD_API,
    PUT_DELETE_FIELD_API:PUT_DELETE_FIELD_API,
    ALL_FIELD_CMS_API:ALL_FIELD_CMS_API,
    GET_POST_CHARGEBOX_TYPE_API:GET_POST_CHARGEBOX_TYPE_API,
    PUT_DELETE_CHARGEBOX_TYPE_API:PUT_DELETE_CHARGEBOX_TYPE_API,
    VERIFY_SYSTEM_USER_MAIL_API:VERIFY_SYSTEM_USER_MAIL_API,
    GET_REMAING_SYSTEM_FIELD_API:GET_REMAING_SYSTEM_FIELD_API ,
    GET_SYSTEM_USER_BY_TYPE_API:GET_SYSTEM_USER_BY_TYPE_API ,
    PUT_SYSTEM_FIELD_DETAIL_API:PUT_SYSTEM_FIELD_DETAIL_API,
    GET_POST_CUSTOMER_API:GET_POST_CUSTOMER_API,
    VERIFY_CUSTOMER_MAIL_API:VERIFY_CUSTOMER_MAIL_API,
    PUT_DELETE_SYSTEM_USER_API:PUT_DELETE_SYSTEM_USER_API ,
    GET_SYSTEM_USER_DETAIL_API:GET_SYSTEM_USER_DETAIL_API,
    DELETE_CUSTOMER_API :DELETE_CUSTOMER_API,
    GET_CUSTOMER_DETAIL_API:GET_CUSTOMER_DETAIL_API ,
    GET_CHARGEBOX_API : GET_CHARGEBOX_API ,
    GET_PUT_CHARGEBOX_DETAIL_API: GET_PUT_CHARGEBOX_DETAIL_API,
    GET_CHARGEBOX_PRODUCT_TYPE_API:GET_CHARGEBOX_PRODUCT_TYPE_API,
    PUT_SYSTEM_USER_PASSWORD_API:PUT_SYSTEM_USER_PASSWORD_API

}
export default APIs;