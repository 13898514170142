import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '@store/ContextStore';
import util from 'util';
import requestFn from '@utils/request';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { getLocalStore, setSessionStore } from '@utils/util';
import { IErrorResponse } from '@interface/Response/IErrorResponse';
import { Box } from '@mui/material';
import { useNavigate, useRoutes } from 'react-router-dom';
import routes from '@routes/routes';
import { AuthContextType } from '@provider/AuthProvider';
import useAuth from '@use/useAuth';

function App() {
  
  const routing = useRoutes(routes);

  const navigate = useNavigate();
  let auth  = useAuth();
  let { user , getUserInfo } = auth as AuthContextType;

  useEffect(()=>{
    let RSID = getLocalStore("RSID");
    if(RSID){
      getUserInfo( () => navigate("/admin", { replace: true }))
    } else if(!user){
      navigate("/login");
    }
  }, []);

  return (
    <>
      {
        routing
      }
    </>
  );
}

export default App;


