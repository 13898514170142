import APIs from "@config/apis";
import { ISystemUser, ISystemPermission } from "@interface/IUser";
import { AppContext } from "@store/ContextStore";
import requestFn from "@utils/request";
import { getLocalStore, getSessionStore, removeAllLocalStore, removeAllSessionStore, setLocalStore, setSessionStore } from "@utils/util";
import { useSnackbar } from "notistack";
import { createContext , useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IErrorResponse } from "@interface/Response/IErrorResponse";
import util from 'util';
import useSystemUserData from "@use/useSystemUserData";

export interface AuthContextType {
    user: ISystemUser | null;
    getUserInfo:(callback: VoidFunction) => void ,
    signIn: ( account : string , password:string , callback: VoidFunction) => void;
    signOut: (callback: VoidFunction) => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

const fakeAuthProvider = {
    isAuthenticated: false,
    getUserInfo(callback: VoidFunction) {
        callback();
    } ,
    signIn(callback: VoidFunction) {
        this.isAuthenticated = true;
        callback();
    },
    signOut(callback: VoidFunction) {
        this.isAuthenticated = false;
        setTimeout(callback, 100);
    },
};

export const AuthProvider = ({children}:{children:  React.ReactNode}) => {
    
    const [user, setUser] = useState<ISystemUser|null>(null);
    
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch } = useContext(AppContext);

    const loginRequest = useCallback(( account:string , password:string , callback:VoidFunction)=>{
        const fetchData = async ()=>{
            await requestFn(dispatch, {
                url: util.format(APIs.POST_SYSTEM_LOGIN_API),
                method: 'post' ,
                data : {
                    account : account ,
                    password : password
                }
            }).then((response)=>{
                if(response.status === 200){
                    let data = response.data as { token_type:string, access_token:string ,refresh_token:string,expires_in:number}
                    setSessionStore("SID" , data.access_token);
                    setLocalStore("RSID" , data.refresh_token);
                    callback();
                }
            }).catch((message:IErrorResponse)=>{
                enqueueSnackbar(message.message , { variant:"error" } );
            });
            
        }
        fetchData();
    },[dispatch]);

    const logOutRequest = useCallback(( refreshToken:string , callback:VoidFunction)=>{
        const fetchData = async ()=>{
            await requestFn(dispatch, {
                url: util.format(APIs.SYSTEM_LOGOUT_API),
                method: 'post' ,
                data : {
                    refreshToken : refreshToken 
                }
            }).then((response)=>{
                if(response.status === 200){
                    setUser(null);
                    removeAllSessionStore();
                    removeAllLocalStore();
                    callback();
                }
            }).catch((message:IErrorResponse)=>{
                enqueueSnackbar(message.message , { variant:"error" } );
            });
            
        }
        fetchData();
    },[dispatch]);

    const getSystemUserInfo = useCallback(( callback:VoidFunction)=>{
        const fetchData = async ()=>{
            await requestFn(dispatch, {
                url: util.format(APIs.GET_POST_SYSTEM_USER_API),
                method: 'get' ,
                data : {}
            }).then((response)=>{
                if(response.status === 200){
                    let userData = response.data.user as ISystemUser;
                    setUser(()=>{
                        return userData;
                    })
                    callback();
                }
            }).catch((message:IErrorResponse)=>{
                enqueueSnackbar(message.message , { variant:"error" } );
            });
            
        }
        fetchData();
    },[dispatch]);

    let signIn = (account: string , password:string , callback: VoidFunction) => {
        return fakeAuthProvider.signIn(() => {
            loginRequest(account , password , callback);
        });
    };
    let getUserInfo = (callback: VoidFunction) => {
        return fakeAuthProvider.getUserInfo(()=>{
            getSystemUserInfo(callback);
        })
    }
    let signOut = (callback: VoidFunction) => {
        return fakeAuthProvider.signOut(() => {
            let RSID = getLocalStore("RSID");
            logOutRequest(RSID,callback);
        });
    };

    return (
        <AuthContext.Provider value={{ user , getUserInfo , signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
    
}