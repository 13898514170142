import FetchBreadcrumbNameActions from '@store/actions/fetchBreadcrumbNameActions';

/** State 接口 */
export interface FetchBreadcrumbNameState {
  breadcrumbName: string
}

/** 初始狀態 */
export const INITIAL_FETCH_BREADCRUMBNAME_STATE: FetchBreadcrumbNameState = {
  breadcrumbName: ""
}


/** 默認 reducer */
export default function fetchBreadcrumbNameReducer(state: FetchBreadcrumbNameState = INITIAL_FETCH_BREADCRUMBNAME_STATE, action: FetchBreadcrumbNameActions ) {
  switch (action.type) {
    case 'SAVE_BREADCRUMBNAME': {
      return {
        ...state,
        breadcrumbName: action.payload.breadcrumbName
      }
    }

    default:
      return state
  }
}
