import loadable from '@loadable/component';
import Loading from '@components/Loading';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { IPageProps } from '@components/Page';
import useAuth from '@use/useAuth';
import { AuthContextType } from '@provider/AuthProvider';

const MainLayout = loadable( () => import('@components/Main') , {
    fallback:<Loading />,
});

const Login = loadable( () => import('@views/Login') , {
    fallback:<Loading />,
});

const LauncherLoading = loadable( () => import('@views/LauncherLoading') , {
    fallback:<Loading />,
});

const Main = loadable( () => import('@views/Main') , {
    fallback:<Loading />,
});

//分類管理
const Installer = loadable( () => import('@views/Manage/Installer/List') , {
    fallback:<Loading />,
});

const EditInstallerDetail = loadable( () => import('@views/Manage/Installer/Detail') , {
    fallback:<Loading />,
});


const Community = loadable( () => import('@views/Manage/Community/List') , {
    fallback:<Loading />,
});

const CommunityDetail = loadable( () => import('@views/Manage/Community/Detail') , {
    fallback:<Loading />,
});

const Parking = loadable( () => import('@views/Manage/Parking/List') , {
    fallback:<Loading />,
});

const ParkingDetail = loadable( () => import('@views/Manage/Parking/Detail') , {
    fallback:<Loading />,
});

const Customer = loadable( () => import('@views/Manage/Customer/List') , {
    fallback:<Loading />,
});

const CustomerDetail = loadable( () => import('@views/Manage/Customer/Detail') , {
    fallback:<Loading />,
});

// 設定
const AddInstallerDetail = loadable( () => import('@views/Setting/Installer/Detail') , {
    fallback:<Loading />,
});

const AddUserDetail = loadable( () => import('@views/Setting/Customer/Detail') , {
    fallback:<Loading />,
});

const AddCommunityDetail = loadable( () => import('@views/Setting/Community/Detail') , {
    fallback:<Loading />,
});

const AddParkingAttendantDetailDetail = loadable( () => import('@views/Setting/Parking/ParkingAttendantDetail') , {
    fallback:<Loading />,
});

const SettingParkingLotDetailDetail = loadable( () => import('@views/Setting/Parking/ParkingLotDetail') , {
    fallback:<Loading />,
});

const AddParkingDetail = loadable( () => import('@views/Setting/Parking/ParkingLotDetail') , {
    fallback:<Loading />,
});

const PermissionDetail = loadable( () => import('@views/Setting/Permission/Detail') , {
    fallback:<Loading />,
});

const PermissionList = loadable( () => import('@views/Setting/Permission/List') , {
    fallback:<Loading />,
});


const FieldList = loadable( () => import('@views/Setting/Field/FieldList') , {
    fallback:<Loading />,
});

const CMSList = loadable( () => import('@views/Setting/Field/CMSList') , {
    fallback:<Loading />,
});

const TypeList = loadable( () => import('@views/Setting/ChargeBox/TypeList') , {
    fallback:<Loading />,
});

const TypeDetail = loadable( () => import('@views/Setting/ChargeBox/TypeDetail') , {
    fallback:<Loading />,
});

const Revenue = loadable( () => import('@views/Report/Revenue') , {
    fallback:<Loading />,
});

const PowerPay = loadable( () => import('@views/PowerPay/') , {
    fallback:<Loading />,
});

const PowerAnayle = loadable( () => import('@views/PowerAnayle/List') , {
    fallback:<Loading />,
});

const Field = loadable( () => import('@views/Manage/Field/List') , {
    fallback:<Loading />,
});

const FieldDetail = loadable( () => import('@views/Manage/Field/Detail') , {
    fallback:<Loading />,
});

const RequireAuth = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let auth  = useAuth();
    let { user } = auth as AuthContextType;
    if(!user || user?.userId == ""){
        return <Navigate to="/login" state={{ from: location }} />;
    }
    return <Outlet />;
}

const routes:Array<IPageProps>  = [
    {
        path:"/",
        element:<LauncherLoading />
    } ,
    {
        path:"/login",
        element:<Login />
    } ,
    {
        path:"/admin/",
        element:<MainLayout><RequireAuth /></MainLayout>,
        children:[
            {
                path:"/admin/",
                element:<Main />
            } ,
            {
                path:"/admin/manage/installer/",
                element:<Installer />,
                
            } ,
            {
                path:"/admin/manage/installer/detail",
                element:<EditInstallerDetail />
            } ,
            {
                path:"/admin/manage/community/",
                element:<Community />
            } ,
            {
                path:"/admin/manage/community/detail",
                element:<CommunityDetail />
            } ,
            {
                path:"/admin/manage/parkingattendant/",
                element:<Parking />
            } ,
            {
                path:"/admin/manage/parkingattendant/detail",
                element:<ParkingDetail />
            } ,
            {
                path:"/admin/manage/customer/",
                element:<Customer />
            } ,
            {
                path:"/admin/manage/customer/detail",
                element:<CustomerDetail />
            } ,
            {
                path:"/admin/manage/field/",
                element:<Field />
            } ,
            {
                path:"/admin/manage/field/chargebox/detail",
                element:<FieldDetail />
            } ,
            {
                path:"/admin/poweranayle",
                element:<PowerAnayle />
            },
            {
                path:"/admin/powerpay",
                element:<PowerPay />
            },
            {
                path:"/admin/report/revenue",
                element:<Revenue />
            },

            {
                path:"/admin/setting/installer",
                element:<AddInstallerDetail />
            } ,
            {
                path:"/admin/setting/customer",
                element:<AddUserDetail />
            } ,
            {
                path:"/admin/setting/Community",
                element:<AddCommunityDetail />
            } ,
            {
                path:"/admin/setting/parkingattendant",
                element:<AddParkingAttendantDetailDetail />
            } ,
            {
                path:"/admin/setting/parkinglot",
                element:<SettingParkingLotDetailDetail />
            } ,
            {
                path:"/admin/setting/parking",
                element:<AddParkingDetail />
            } ,
            {
                path:"/admin/setting/permission",
                element:<PermissionList />
            } ,
            {
                path:"/admin/setting/permission/detail",
                element:<PermissionDetail />
            } ,
            {
                path:"/admin/setting/field",
                element:<FieldList />
            } ,
            {
                path:"/admin/setting/field/cms",
                element:<CMSList />
            } ,
            {
                path:"/admin/setting/chargebox/type",
                element:<TypeList />
            },
            {
                path:"/admin/setting/chargebox/type/detail",
                element:<TypeDetail />
            },

            
        ]
    }
]
export default routes;